@media screen and (min-width: 320px) and (max-width: 450px) {
  .scroll-item p {
    font-size: 18px !important;
  }
  .seleted-title {
    font-size: 16px !important;
  }
  .mainproduct-rowone span {
    font-size: 26px !important;
  }
  .text {
    font-size: 16px !important;
  }
  .selected-itemsubhead {
    font-size: 18px !important;
  }
  .mainproduct-cardsection {
    padding-top: 0px !important;
  }
  .MuiPaper-elevation {
    transition: none !important ;
  }

  .MuiPaper-elevation:hover {
    transition: none !important ;
    transform: none !important ;
  }
}

.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}

@media screen and (min-width: 320px) and (max-width: 390px) {
  .sub-heading span {
    font-size: 24px !important;
  }
  .bottom-bg {
    background-image: url("../../../Assets/products/leftdoodle.svg");
    background-repeat: repeat !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .sub-heading span {
    font-size: 26px !important;
  }
  .secrh-filterdiv {
    padding-bottom: 30px !important;
    position: relative;
    padding-top: 12px;
    margin-right: 1px !important;
  }
  .bottom-bg {
    background-image: url("../../../Assets/products/leftdoodle.svg");
    background-repeat: repeat !important;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1960px) {
  .mainproduct-rowone span {
    font-size: 26px !important;
  }
  .text {
    font-size: 16px !important;
  }
  .scroll-item p {
    font-size: 17px !important;
  }
  .selected-itemhead {
    font-size: 26px !important;
  }
  .selected-itemsubhead {
    font-size: 18px !important;
  }
  .seleted-title {
    font-size: 16px !important;
    line-height: 19px !important;
  }
}

.products {
  position: relative;
  background-color: #30884d15;
  padding-bottom: 125px;
  background-image: url("../../../Assets/products/Group339.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  background-size: contain;
}

.HorizontalScroll .HorizontalScrollTrack {
  display: none !important;
}

.products-bottomflower .product-flower {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16%;
  z-index: 999;
}
.product-head {
  height: 100px;
}

.product-card {
  padding: 10px;
  background: #ffffff;

  min-height: 150px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12) !important;
  border-radius: 20px !important;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
}

.product-card:active {
  box-shadow: 0px 8px 8px rgb(144, 140, 140);
}
.product-cardimg {
  object-fit: contain !important;
  width: 70% !important;
  margin: auto;
  min-height: 100px !important;
}
.product-cardtitle {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 200;
  font-size: 12px !important;
  line-height: 16px !important;

  text-align: center !important;
  text-transform: capitalize;

  color: #000000;
}

.product-cardcontent {
  min-height: 50px;
  padding-bottom: 0px !important;
}
.mainproduct-rowone {
  text-align: left;
  margin-bottom: 20px;
}
.mainproduct-rowtwo {
  margin-bottom: 20px;

  width: fit-content;
}

.mainproduct-rowtwo .product-card {
}
.mainproduct-rowone span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;

  color: #232323;
}
.mainproduct-rowone p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
  opacity: 0.5;
  text-align: center;
}

.scroll .MuiPaper-root {
  border-radius: 20px;
  width: 250px;
  height: 180px;
  margin-bottom: 30px;
  background-color: transparent;
}

.scroll .css-s18byi {
  background-color: transparent !important;
  box-shadow: none !important;
}

.MuiPaper-elevation {
  transition: 0.4s ease-in !important;
}

.MuiPaper-elevation:hover {
  transition: 0.8s ease-out !important;
  transform: scale(1.1);
}

.MuiPaper-elevation::selection {
  font-weight: 600 !important;
  opacity: 1 !important;
  color: red !important;
}

.scroll {
  box-sizing: border-box;
  display: flex;
  overflow-x: scroll;
  padding: 14px;
  width: 100%;
  scroll-padding: 24px;
  border-radius: 8px;
}

.scroll-item {
  padding: 10px;
  border-radius: 8px;
  scroll-snap-align: start;
  width: 100%;
}

.scroll img {
  object-fit: contain;
  width: 100% !important;
  height: 100px !important;
}

.scroll-item p {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000 !important;
  opacity: 0.75;
}

.scroll-item::selection {
  font-weight: 600 !important;
  opacity: 1 !important;
  color: red !important;
}

.MuiPaper-root:active {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #b11515 !important;
}

.selected-card {
  margin-right: 15px !important;
  margin-bottom: 15px !important;
  min-height: 300px !important;

  filter: drop-shadow(3px 3px 12px rgba(0, 0, 0, 0.12)) !important;
  border-radius: 16px !important;
  max-width: 197px !important;
}

.selected-image {
  /* width: 50% !important; */
  padding: 10px;
  min-height: 150px !important;
  margin: auto;
  object-fit: contain !important;
}

.category-selected-image {
  width: 100% !important;
  min-height: 150px !important;
  margin: auto;
  margin-top: 30px;
  box-shadow: 0px 6px 12px 2px rgba(0.97, -0.24, 0, 0) !important;
}

.selectedproduct-rowone img {
  object-fit: contain !important;
  max-height: 200px;
}

.selectedproduct-rowone {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.selected-itemhead {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 32px;
}
.selected-itemsubhead {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
  opacity: 0.4;
}

.seleted-title {
  min-height: 45px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;

  text-align: center !important;
  text-transform: capitalize;

  color: #000000;
}
.selected-gram {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 18px;
  text-transform: lowercase;
  color: #000000;
}
.selected-cardaction .selected-btn {
  float: right !important;
  display: flex !important;
  justify-content: flex-end !important;
}
.selectedproduct-cardsection {
  padding-top: 20px;
}
.selected-btn {
  min-width: 35px !important;
  color: #ffffff !important;
  height: 35px !important;
  /* background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important; */
  border-radius: 15px 5px !important;
  background-color: #499e4c !important;
}
.selected-card .selected-btn {
  margin: auto -9px -10px 0px;
}
.selected-arrow {
  text-align: center;
  margin: auto;
}

.text-center {
  margin-top: 50%;
  margin-bottom: 50%;
}

.text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
  opacity: 0.5 !important;
}
.mainproduct-cardsection {
  padding-top: 30px;
  position: relative;
  background-image: url("../../../Assets/products/topdoodle.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: contain;
}
@media (max-width: 992px) {
  .mainproduct-cardsection .product-card {
    margin: auto !important;
  }

  .selectedproduct-cardsection .selected-card {
    margin: auto !important;
  }
  .mainproduct-rowtwo {
  }
  .product-cardtitle {
    font-size: 10px !important;
  }
  .product-card {
    width: 150px !important;
  }
}

.css-3zukih {
  justify-content: space-between !important;
}

#products .secrh-filterdiv {
  position: absolute;
  right: 0;
  display: none;
}

::-webkit-scrollbar {
  max-width: 50% !important;

  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #d4d4d4;
  width: 50% !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(65, 165, 107, 0.719);
}

::-webkit-scrollbar:vertical {
  display: none;
}

@media (max-width: 1024px) {
  .scroll {
    gap: 110px;
  }
}

@media (max-width: 780px) {
  .scroll {
    gap: 40px;
  }
}

@media (max-width: 780px) {
  .scroll {
    scroll-snap-align: start !important;
    scroll-snap-type: x mandatory !important;
    width: 100vw !important;
  }
}

@media (max-width: 780px) {
  .item-scroll .MuiPaper-root {
    width: 197px !important;
  }
}

@media (max-width: 760px) {
  .secrh-filterdiv {
    padding-bottom: 30px !important;
    position: relative;
  }
}

.css-19slclm-MuiPaper-root-MuiCard-root {
  box-shadow: 0px 9px 1px #499E4C(243 9 76 / 19%);
}

.selectedproduct-rowone .MuiPaper-root {
  margin-bottom: 20px !important;
  box-shadow: 0px 10px #499e4c;
}

.selected-text {
  font-weight: bold !important;
  color: black;
  opacity: 0.8 !important;
}

.css-1t6e9jv-MuiCardActions-root {
  display: flex;
  justify-content: space-between;
}

.scroll-item .css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
  background-color: transparent !important;
}

.bottom-bg {
  background-image: url("../../../Assets/products/leftdoodle.svg");
  background-repeat: no-repeat;
}

.right-bg {
  background-image: url("../../../Assets/products/rightdoodle.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  background-position-y: center;
}

.gm-view {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: auto;
}

.selected-item-col {
  width: 20%;
  margin-bottom: 15px;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent !important;
}

.product-circle {
  padding-top: 50px;
}

@media screen and (max-width: 450px) {
  .selected-item-col {
    width: 100% !important;
  }
  .scroll .MuiPaper-root {
    width: 170px !important;
  }
  .scroll-item {
    padding: 0px !important;
  }
}

.mainproduct-rowone .carousel-control-prev {
  left: -90px;
}

.mainproduct-rowone .carousel-control-next {
  right: -90px;
}

.mainproduct-rowone .carousel-control-prev-icon {
  background-image: url("../../../Assets/products/prev.svg") !important;
  background-repeat: no-repeat;
  width: 15px;
}

.mainproduct-rowone .carousel-control-next-icon {
  background-image: url("../../../Assets/products/next.svg") !important;
  background-repeat: no-repeat;
  width: 15px;
}
