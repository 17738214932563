.footerdiv {
  background: url("../../Assets/Footer/footerbg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.footer-logo {
  width: 15%;
  padding-top: 100px;
  padding-bottom: 20px;
  margin: auto;
}
.footersubhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.7;
  width: 180px;
  margin: auto;
}
.footerlogolist {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
}
.footercontainer .footerlist {
  display: flex;
  justify-content: center;
  align-items: center !important;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}

.footerlist li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 0.9;
  padding: 10px;
}
.footerlist a {
  text-decoration: none;
  text-align: center;
  color: #fff !important;
}

.footerlist a:hover {
  color: black !important;
}
.footerbtn {
  border-radius: 30px !important;
  background-color: #fff !important;
  width: 40px;
  height: 40px;
  border: none;
}

.footerbtn img {
  vertical-align: middle;
  display: flex;
  margin: auto;
}

.footerlogolist li {
  padding: 10px;
}

.copyrightdiv {
  background: rgba(0, 0, 0, 0.2);
  padding-top: 20px;
  padding-bottom: 40px;
}
.copyrightdiv span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #ced4da;
  opacity: 0.9;
}

.copyrightdiv a {
  color: #ced4da;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  opacity: 0.93;
}

.copyrightdiv a:hover {
  text-decoration: underline;
  color: #f1e9e9;
}

.copyrightdiv .bold-text {
  color: #fff;
}
.lefttext {
  float: left;
  padding-left: 20px;
}
.righttext {
  float: right;
  padding-right: 20px;
}
@media screen and (max-width: 900px) {
  .footerbtn {
    border-radius: 30px !important;
    background-color: #fff !important;
    width: 30px !important;
    height: 30px !important;
    border: none;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .footer-logo {
    width: 175px !important;
  }
  .footerlogolist img {
    width: 17px !important;
    display: flex;
  }

  .footerlist {
    display: grid !important;
  }

  .footerlist li {
    padding-bottom: 0px !important;
    padding-top: 10px !important;
    font-size: 15px !important;
  }
  .lefttext {
    float: none !important;
    text-align: justify !important;
    padding-left: 10px !important;
    font-size: 12px !important;
  }
  .righttext {
    float: none !important;
    padding-right: 10px !important;
    font-size: 12px !important;
  }

  .copyrightdiv {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .footersubhead {
    font-size: 15px !important;
  }
  .footerlogolist li {
    padding: 5px !important;
    padding-top: 30px !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 900px) {
  .copyrightdiv a {
    font-size: 10px !important;
    padding-left: 2px;
  }

  .copyrightdiv {
    text-align: center !important;
    display: grid !important;
    justify-content: center;
  }
  .footerlist li {
    padding-bottom: 0px !important;
    padding-top: 10px !important;
    font-size: 15px !important;
  }
  .footerlogolist li {
    padding: 5px !important;
    padding-top: 30px !important;
  }
  .lefttext {
    float: none !important;
    text-align: justify !important;
    padding-left: 10px !important;
    font-size: 12px !important;
  }
  .righttext {
    float: none !important;
    padding-right: 10px !important;
    font-size: 12px !important;
  }
  footer-logo {
    width: 175px !important;
  }
}
