.home {
  position: relative;
  overflow: hidden;
}

.home .headerhome {
  position: absolute;
  z-index: 999;
  width: 100vw;
}

input {
  caret-color: rgba(45, 157, 75, 1);
}
.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}

.MuiCircularProgress {
  color: rgba(45, 157, 75, 1) !important;
}
