@media screen and (min-width: 3500px) and (max-width: 4000px) {
  .left-top {
    background-image: url("../../../../Assets/containerfive/left-top-img.png");
    background-repeat: no-repeat;
    background-size: 12% !important;
  }
  .five-bg {
    background-image: url("../../../../Assets/containerfive/doodleart.svg") !important;
    background-repeat: no-repeat;
    background-size: cover !important;
  }
  .right-bottom {
    background-image: url("../../../../Assets/containerfive/right-bottom-img.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 16% !important;
  }
  .five-content p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 30px !important;
    line-height: 40px !important;
    text-align: justify;
    color: white;
    padding: 0px 200px;
    margin-bottom: 80px;
  }
  .five-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 60px !important;
    line-height: 46px;
    color: #daeb3a;
    margin-bottom: 50px;
    padding-top: 150px;
  }
}

@media screen and (max-width: 424px) {
  .left-top {
    background-size: 68% !important;
  }
  .left-bottom {
    background-size: 47% !important;
  }
  .right-bottom {
    background-size: 73% !important;
  }

  .five-content p {
    padding: 0px 14px !important;
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .five-heading {
    margin-bottom: 30px !important;
    padding-top: 170px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    font-size: 28px !important;
    line-height: 38px !important;
  }
  .five-content img {
    margin-bottom: 210px !important;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1935px) {
  .five-content p {
    font-size: 20px !important;
    line-height: 34px !important;
    padding: 0px 250px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 912px) {
  .left-top {
    background-size: 37% !important;
  }
  .left-bottom {
    background-size: 36% !important;
  }
  .right-bottom {
    background-size: 50% !important;
  }

  .five-content p {
    padding: 0px 65px !important;
  }

  .five-heading {
    margin-bottom: 30px !important;
  }
  .five-content img {
    margin-bottom: 210px !important;
  }
}

.five-art {
  background: linear-gradient(
    180deg,
    #4fad53 -16.45%,
    #3c823e 50.77%,
    #479c4a 119.4%
  );
}

.five-bg {
  background-image: url("../../../../Assets/containerfive/doodleart.svg") !important;
  background-repeat: no-repeat;
}

.left-top {
  background-image: url("../../../../Assets/containerfive/left-top-img.png");
  background-repeat: no-repeat;
  background-size: 30%;
}

.left-bottom {
  background-image: url("../../../../Assets/containerfive/left-bottom-img.png");
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.right-bottom {
  background-image: url("../../../../Assets/containerfive/right-bottom-img.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 45%;
}

.five-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  line-height: 46px;
  color: #daeb3a;
  margin-bottom: 50px;
  padding-top: 150px;
}

.five-content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: justify;
  color: white;
  padding: 0px 200px;
  margin-bottom: 80px;
}

.five-content img {
  margin-bottom: 180px;
}
