@media screen and (min-width: 1350px) and (max-width: 1370px) {
  .right-bottom1 {
    margin-top: 31.8% !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 8300px) {

}
@media screen and (min-width: 819px) and (max-width: 821px) {
  .right-bottom1 {
    position: absolute;
    margin-top: 60% !important;
    right: 0% !important;
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 760px) and (max-width: 900px) {
  .four-heading {
    padding-top: 0px !important;
  }
  .cooking-col img {
    width: 100% !important;
    padding-top: 610px !important;
  }
  .round-top img {
    display: none !important;
    left: -12% !important;
  }
  .right-bottom1 {
    position: absolute;
    margin-top: 64% !important;
    right: 0% !important;
    display: flex;
    justify-content: flex-end;
  }
  .fd2 img {
    display: none !important;
  }
  .left-top1 img {
    /* display: none !important; */
  }
  .fd1 img {
    float: left;
    transform: translate(-25px, 135%) !important;
    object-fit: none;
    display: none !important;
  }
  .indian-content {
    position: absolute !important;
    top: 27% !important;
  }
  .four-content p {
    margin-left: 100px !important;
  }
  .round-top img {
    display: none !important;
  }
}

@media screen and (min-width: 3500px) and (max-width: 4000px) {
  .span1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 80px !important;
    line-height: 27px;
    text-align: center;
    color: #a43429;
  }
  .span2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 50px !important;
    line-height: 50px !important;
    text-align: center;
    color: #a43429;
  }
  .left-top1 img {
    width: 200px !important;
  }
  .right-bottom1 {
    position: absolute;
    margin-top: 11.3% !important;
    right: 7.5% !important;
    display: flex;
    justify-content:flex-end ;
  }
  .fd2 img {
    background-repeat: no-repeat;
    transform: translate(-120%, 589%) !important;
  }
  .four-content p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 30px !important;
    line-height: 40px !important;
    text-align: justify;
    color: #212121;
    margin-top: 50px;
  }
  .cooking-col {
    width: 24.777888% !important;
  }
  .cooking-col img {
    transform: translate(270px, 0px) !important;
  }
  .round-top {
    position: absolute;
    right: 7.5% !important;
    margin-top: 15px;
    z-index: 99 !important;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1935px) {
  .color-bg {
    background-size: contain !important;
  }
  .four-content p{
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .cooking-col img {
    width: 28vw !important;
  }

  .right-bottom1 {
    margin-top: 31.6% !important;
    right: 17% !important;
  }
  .fd2 img {
    background-repeat: no-repeat;
    transform: translate(15%, 253%) !important;
    width: -webkit-fill-available !important;
  }
  .round-top {
    right: 17% !important;
  }
  .fd1 img {
    transform: translate(-25px, 85%) !important;
  }
  .indian-content {
    margin-top: 12.5% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .right-bottom1 {
    display: none !important;
  }
  .four-content p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 27px !important;
    text-align: justify;
    color: #212121;
    margin-top: 50px;
  }
  .fd2 img {
    display: none !important;
  }
  .round-top {
    display: none !important;
  }
  .left-top1 {
    display: none !important;
  }
  .fd1 img {
    display: none !important;
  }
  .four-content {
    margin-right: 15px !important;
    margin-left: 15px !important;
    padding-top: 35px !important;
  }
  .four-heading {
    padding-top: 70px !important;
  }
  .cooking-col {
    display: none !important;
  }
  .four-content p {
    margin-top: -2px !important;
  }
  .color-bg {
    background-size: cover !important;
  }
  .span1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 38px !important;
    line-height: 27px;
    text-align: center;
    color: #a43429;
  }

  .span2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 25px !important;
    line-height: 27px;
    text-align: center;
    color: #a43429;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .right-bottom1 {
    margin-top: 30% !important;
  }
}

.color-bg {
  background-image: url("../../../../Assets/container4/yellow-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.left-top1 {
  position: absolute;
  left: 10%;
  margin-top: 15px;
}

.round-top {
  position: absolute;
  right: 13%;
  margin-top: 15px;
}

.right-bottom1 {
  position: absolute;
  margin-top: 33.5%;
  right: 14%;
  display: flex;
  justify-content: flex-end;
}

.four-heading {
  padding-top: 90px;
}

.span1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 27px;
  text-align: center;
  color: #a43429;
}

.span2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 27px;
  text-align: center;
  color: #a43429;
}

.four-content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: justify;
  color: #212121;
  margin-top: 50px;
}

.four-content {
  margin-right: 100px;
}

.fd2 img {
  background-repeat: no-repeat;
  transform: translate(55%, 249%);
}
.fd1 img {
  float: left;
  transform: translate(-25px, 60%);
  object-fit: none;
}

/* .cooking-col{
  background-image: url("../../../../Assets/container4/cook.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 316px;
} */
