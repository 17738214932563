.brand-section {
  margin-top: 10px;
  margin-bottom: 10px;
}

.brand-cluster img{
    width: 100%;
}

.brand-details {
  padding-top: 0px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .brand-details {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.brand-section {
  background-image: url("../../../Assets/Brand/ob-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.navBtn {
  height: 45px;
  background: rgba(45, 157, 75, 1);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  color: white;
  border: none;
  padding: 0px 10px;
}

.navBtn:hover {
  background-color: rgba(45, 157, 75, 1) !important;
  border-color: rgba(45, 157, 75, 1) !important;
  color: white !important;
}

.brand-title {
  color: #000;
  font-family: Poppins;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  padding: 23px 0 30px 0;
}

.brand-desc {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 16px;
}

.nodata-layout {
  height: 600px;
}
