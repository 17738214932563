@media screen and (min-width: 320px) and (max-width: 450px) {
  .img-title {
    font-size: 18px !important;
  }
  .brochure-div {
    width: 330px !important;
  }
  .video-grid {
    width: 330px !important;
  }
  .gallerybtn-div {
    border-radius: 5px !important;
  }
  .br {
    padding-bottom: 20px !important;
  }
}

.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}

@media screen and (min-width: 1900px) and (max-width: 1960px) {
  .gallerybtn-div p {
    font-size: 16px !important;
  }
  .gallery-containerrowone span {
    font-size: 26px !important;
  }
  .selected-sectionfirstrow p {
    font-size: 26px !important;
  }
  .img-title {
    font-size: 16px !important;
  }
  .video-desc {
    font-size: 16px !important;
  }
  .video-date {
    font-size: 14px !important;
  }
}
.gallery-containerrowone span {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #232323;
}
.gallery-containerrowone {
  text-align: left;
  margin-bottom: 50px;
  margin-top: 10px;
}
.gallery-containerrowone p {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
  opacity: 0.5;
}

.gallery-img {
  position: absolute;
  right: 0;
  width: 200px;
  height: 200px;
  top: 7%;
}

.gallery-click {
  display: flex;
  justify-content: space-around;
}
.gallerybtn-div {
  width: 160px;
  height: 140px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  margin: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.selected {
  box-shadow: 0px 6px 12px 2px rgba(0, 0, 0, 0.16);
}

.gallerybtn-div p:active {
  font-weight: 600;
}

.gallerybtn-div img {
  margin: auto;
  display: grid;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
}
.gallerybtn-div p {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.selected-sectionfirstrow {
  text-align: left;
  margin-top: 45px;
  margin-bottom: 30px;
}
.selected-sectionfirstrow span {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
  opacity: 0.4;
}
.selected-sectionfirstrow p {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
}
.selected-section {
  margin-top: 20px;
  margin-bottom: 80px;
}

.ytp-cued-thumbnail-overlay-image {
  border-top-right-radius: 19px !important;
  border-top-left-radius: 30px !important;
}
.reactplayer {
  max-width: 100%;
  max-height: 250px;
  border-top-right-radius: 19px !important;
  border-top-left-radius: 30px !important;
}
.video-grid {
  background: #ffffff;
  width: 350px;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px !important;
  padding: 0px;
  margin-right: 15px;
  margin: auto;
  margin-bottom: 20px;
}
.manin-videosection {
  position: relative;
  min-height: 250px;
}
.video-content {
  padding: 15px;
}
.video-title {
  position: absolute;
  visibility: visible;
  top: 180px;
  left: 20px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-shadow: 5px 5px 20px black;
  color: #ffffff;
}
.video-desc {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: #212121;
  opacity: 0.8;
  margin-bottom: 3px;
}
.video-date {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  text-transform: capitalize;
  text-align: left;
  color: #313131;
  opacity: 0.4;
}
.video {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.rh5v-Overlay_inner {
  border-radius: 30px !important;
  border: 3px solid white !important;
  background-color: transparent !important;
}

.rh5v-DefaultPlayer_component {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.image-grid {
  background: hsl(0, 0%, 100%);
  width: 280px !important;
  height: 280px !important;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px !important;
  padding: 0px;
  margin-right: 15px;
  margin: auto;
  /* margin-bottom: 20px; */
  min-height: 200px;
}

.image-grid img {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 12%);
}

.img-row .crd {
  width: 280px;
  /* height: 400px; */
  border-radius: 16px;
  transition: transform 0.5s ease-in;
  border-color: none !important;
  /* box-shadow: 2px 2px 10px rgb(0 0 0 / 12%); */
}

.img-row {
  display: flex;
  justify-content: center;
}

.img-row .crd:hover {
  transform: scale(1.2);
}

.img-row .crd {
  margin-bottom: 50px;
}

.img-title {
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 24px;
  min-height: 60px;
  padding-top: 15px;
  color: #212121;
  font-family: "Poppins" !important;
  font-style: normal !important;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-grid img {
  border-radius: 16px !important;
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}

.brochure-div {
  background: #ffffff;
  width: 400px;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px !important;
  padding: 0px;
  margin-right: 15px;
  margin: auto;
  /* margin-bottom: 20px; */
  min-height: 160px;
  height: 309px;
  /* width: 413px; */
  position: relative;
}
.brochure-div .brochure-mainpic {
  border-radius: 16px !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  object-fit: cover;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12) !important;
}

.brochure-title {
  position: absolute;
  bottom: 0;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  padding: 10px;
}
.brochure-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
}
.brochure-btn button {
  border: none;
  border-radius: 8px;
  background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important;
}

/* .secrh-filterdiv {
  display: flex;
  justify-content: end;
  align-items: center;
} */

.css-1rc40mk-MuiPaper-root {
  background: #ededed !important;
  border-radius: 12px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border: none !important;
  box-shadow: none !important;
}
.css-i4bv87-MuiSvgIcon-root {
  color: rgba(137, 137, 137, 1) !important;
}
.css-yz9k0d-MuiInputBase-input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #000000;
  opacity: 0.4;
}

.gallery-img-row {
  padding-right: none;
}

@media screen and (max-width: 900px) {
  .video-desc {
    font-size: 18px !important;
  }
  .video-date {
    font-size: 14px !important;
  }
  .video-title {
    font-size: 16px !important;
  }

  .brochure-title {
    font-size: 16px !important;
  }

  .gallery-containerrowone span {
    font-size: 26px !important;
  }
  .gallery-containerrowone p {
    font-size: 12px !important;
  }
  .gallerybtn-div {
    width: 90px !important;
    height: 80px !important;
    margin: auto !important;
    z-index: 100;
  }
  .gallerybtn-div img {
    width: 60% !important;
  }
  .gallerybtn-div p {
    font-size: 13px !important;
  }
  .secrh-filterdiv {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .imagemodal img {
    width: 100% !important;
  }
  .img-row .crd:hover {
    transform: none !important;
  }
}

.headergallery {
  height: 100px;
}

.imagemodal img {
  width: 400px;
  height: 400px;
  margin: auto;
  display: flex;
  object-fit: cover;
}

.modal-img-title {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.modal-header {
  border: none;
}

.modal-content {
  border: none;
}

.md-image img {
  object-fit: contain;
}

.modal-content {
  border: none;
  background-color: #f8f9fa;
}
