@media screen and (min-width: 360px) {
  .sub-heading {
  }
}

@media screen and (max-width: 480px) {
  .sub-heading {
    /* padding-bottom: 100px; */
  }

  .sub-heading {
    font-size: 18px !important;
  }
  .category-heading {
    top: 80% !important;
    font-size: 22px !important;
  }
}

.view-header {
  height: 100px;
  z-index: 999;
}
.view-main {
  background: linear-gradient(180deg, #eff9f2 0%, #f4f4f4 100%);
}
.view-maincontainer {
  padding-top: 30px;
  position: relative;
}
.view-mainrow {
}

.sub-heading {
  text-align: left;
}

.sub-heading h1 {
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
}
.sub-heading span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;

  color: #232323;
}
.text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
  opacity: 0.5 !important;
  padding-bottom: 30px;
}

.category-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
