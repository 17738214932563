@media screen and (min-width: 390px) and (max-width: 450px) {
  .recepies-colone {
    padding-bottom: 120px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 380px) {
  .recepies-colone {
    padding-bottom: 80px !important;
  }
}

.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}

.text-center{
  font-size: 18px !important;
}

@media screen and (min-width: 320px) and (max-width: 450px) {
  .recepies-colone span {
    font-size: 27px !important;
  }
  .recepie-method,
  .recepie-time,
  .recepie-subtype,
  .recepie-ingredient {
    padding-top: 10px !important;
  }
  .recepies-colone {
    /* padding-bottom: 110px !important; */
  }
  #recepies textarea,
  input {
    width: 200px !important;
  }
  .recepies-colone p {
    font-size: 16px !important;
    line-height: 27px !important;
  }
  .modal-coloneinnerrowone span {
    font-size: 20px !important;
  }
  .modal-items {
    font-size: 16px !important;
  }
  .modal-type {
    font-size: 14px !important;
  }
  .recepies-colone {
    padding-top: 0px !important;
  }
  .recepie-main {
    padding-bottom: 30px !important;
  }
  .recepie-containertwo {
    padding-bottom: 0px !important;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1960px) {
  .recepies-coltwo img{
    margin-right: -80% !important;
  }
  .modal-items {
    font-size: 16px !important;
  }
  .modal-qty {
    font-size: 14px !important;
  }
  .modal-type {
    font-size: 16px !important;
  }
  .modal-title {
    font-size: 25px !important;
  }
  .modal-steps p {
    font-size: 15px !important;
  }
  .recepies-colone p {
    font-size: 18px !important;
  }
  .recepie-title {
    font-size: 22px !important;
  }
  .recepie-desc {
    font-size: 16px !important;
  }
  .recepie-containertwo span {
    font-size: 26px !important;
  }
  .recepie-containertwo p {
    font-size: 16px !important;
  }
  .recepies-colone span {
    font-size: 36px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 380px) {
  .recepies-colone .MuiPaper-root {
    min-height: 680px !important;
    max-height: 600px;
  }
}
@media screen and (min-width: 390px) and (max-width: 450px) {
  .recepies-colone .MuiPaper-root {
    min-height: 600px !important;
    max-height: 600px;
  }
}

@media screen and (min-width: 1000px) {
  .recepies-colone .MuiPaper-root {
    min-height: 610px !important;
    max-height: 600px !important;
  }
}

.recepies-section {
  position: relative;
}

.recepie-header {
  height: 100px;
}
.recepie-main {
  /* padding-top: 5px; */
  padding-bottom: 110px;
  background-image: url(../../../Assets//Recepies/Subtract.svg);
  background-repeat: no-repeat;

  background-position: right top;

  background-color: #30884d15;
}

.recepies-container {
  margin: auto;
}
.recepies-coltwo img {
  width: 48%;
}

.recepies-colone {
  padding-bottom: 50px;
  padding-top: 50px;
}
.recepies-colone .MuiPaper-root {
  min-height: 610px;
  max-height: 600px;
}

.recepies-colone span {
  font-weight: 800;
  font-size: 32px;
  line-height: 42px;
  font-style: normal !important;
  text-transform: capitalize;
  color: #1e1e1e;
  text-align: left;
}

.recepies-colone p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  color: #212121;
  padding-top: 10px;
  opacity: 0.7;
  text-align: left;
}

.recepie-containeronerow {
  margin-bottom: 20px;
}
.recepie-containertwo span {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;

  color: #232323;
}
.recepie-containertwo {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
}
.recepie-containertwo p {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  color: #212121;

  opacity: 0.5;
}
.recepie-card {
  position: relative;
  background: #ffffff;
  min-height: 400px !important;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px !important;
  position: relative;
  margin: auto;
  margin-bottom: 10px !important;
}
.recepie-card .recepie-image {
  width: 60%;
  position: absolute;
  right: -22px;
  top: 0;
  min-height: 250px !important;
  margin: auto;
  border-radius: 120px;
  transform: translate(50%, 10%);
}

.recepie-btn {
  min-width: 35px !important;
  color: #ffffff !important;
  height: 35px !important;
  background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important;
}
.recepie-card .recepie-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}
.recepie-arrow {
  text-align: center;
}
.recepie-type {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 27px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  text-transform: uppercase;

  color: #313131;

  opacity: 0.4;
}
.recepie-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  color: #313131;
}
.recepie-method,
.recepie-time,
.recepie-subtype,
.recepie-ingredient {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 16px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  padding-top: 20px;

  color: #212121;

  opacity: 0.9 !;
}
.recepie-desc {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  min-height: 60px;
  padding-top: 20px;

  color: #212121;
  font-family: "Poppins" !important;
  font-style: normal !important;
  opacity: 0.9;
}
.recepie-content {
  text-align: left;
  width: 80%;
}

.recepie-card {
  min-height: 530px !important;
}

@media (max-width: 992px) {
  .recepies-colone span {
    font-size: 27px !important;
  }
  .recepies-colone p {
    font-size: 16px !important;
  }

  .recepie-containertworow {
    margin: auto !important;
  }
  .recepie-card {
    margin: auto !important;
  }
  .recepie-method,
  .recepie-time,
  .recepie-subtype,
  .recepie-ingredient {
    font-size: 14px !important;
  }
  .recepie-desc {
    font-size: 15px !important;
  }

  .modal-items {
    font-size: 16px !important;
  }
  .modal-qty {
    font-size: 16px !important;
  }
  .modal-steps p {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  .modal-steps h6 {
    font-size: small !important;
  }
  .modal-method,
  .modal-time,
  .modal-subtype,
  .modal-ingredient {
    font-size: 14px !important;
  }
  .modal-list {
    display: grid !important ;
  }
}

.modal {
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px !important;
}

.modal-body {
  padding: 0px !important;
}
.modal-list {
  display: flex;
  gap: 20px;
}

.modal-steps {
  padding-top: 10px;
}
.modal-steps p {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #212121;

  opacity: 0.9;
}
.modal-btn {
  min-width: 35px !important;
  color: #ffffff !important;
  height: 35px !important;
  background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important;
}

.modal-coltwo {
  position: relative;
  background-color: #30884d15;

  opacity: 0.9;
}
.modal-btnsection {
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 30px;
  padding-right: 10px;
}
.modal-type {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 27px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  text-transform: uppercase;

  color: #313131;

  opacity: 0.4;
  padding-top: 30px;
}
.modal-title {
  font-weight: 700 !important;
  font-size: 23px !important;
  line-height: 27px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  color: #313131;
}
.modal-method,
.modal-time,
.modal-subtype,
.modal-ingredient {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 16px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  padding-top: 20px;
  color: #212121;
  opacity: 0.9 !;
}
.modal-colone {
  position: relative;
}

.modal-coloneinnerrowone span {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  text-align: left;
  color: #212121;
  padding-left: 40px;
  opacity: 0.9;
  padding-top: 46px;
}
.modal-coloneinnerrowthree {
  text-align: left;
  padding-top: 20px;
}
.modal-items {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;

  color: #212121;

  opacity: 0.9;
}
.modal-qty {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  text-transform: capitalize;

  color: #212121;

  opacity: 0.4;
}
