@media screen and (min-width: 320px) and (max-width: 450px) {
  .errcon {
    transform: translate(-29px, -5px) !important;
  }
  .contact-formhead {
    font-size: 18px !important;
  }
  .contact_label {
    font-size: 14px !important;
  }
  .contactus-head {
    font-size: 26px !important;
  }
  .contactus-subhead {
    font-size: 15px !important;
  }
}

.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}

.contactus {
  position: relative;
  background-image: url("../../../Assets/Contactus/Subtract.svg");
  background-repeat: no-repeat;
  background-position: right top;
  overflow: hidden;
  z-index: 10;
}

.contact-head {
  height: 100px;
}

.contactus-div {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 30px;
}

.getin-touchdiv {
  background: #ffffff;
  width: max-content;
  padding: 25px;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  text-align: left;
  float: right;
  height: 100%;
}

.contactus-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #232323;
}
.contactus-colone {
  text-align: left;
}
.contactus-colone .row {
  padding: 0px;
}
.contactus-subhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
  opacity: 0.7;
}
.contactus-colone .contactus-card {
  margin-top: 20px;
}
.contacterror {
  min-height: 50px;
}
.contactus-card {
  background: #ffffff;
  text-align: left;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  max-width: 70%;
  padding: 20px;
}
.contactus-card span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  color: #000000;
  opacity: 0.9;
}
.contactus-card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #212121;
  opacity: 0.7;
}


.registered-Office{

  font-weight: bolder !important;
}

.corporate-Office{
  font-weight: bolder !important;
}

.contactus-card .contactus-address {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #3ba24e;
  opacity: 0.9;
}
.contactus-address:hover {
  color: #000000;
}
.contactus-head p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
  opacity: 0.7;
}
.contact-formhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #313131;
}
.contactus-form {
  padding-top: 20px;
  padding-bottom: 20px;
}
.contactus-form label {
  padding-top: 20px;
  padding-bottom: 10px;
}
.contactus-form .contact-btn {
  margin-top: 0px;
  margin-bottom: 0px;
}
.contacterror {
  min-width: 20px;
  margin-top: 10px;
}
.getin-touchdiv .contact-btn {
  margin-top: 20px;
  margin-bottom: 10px;
}
.css-xilmwd-MuiPaper-root-MuiAlert-root {
  font-size: 12px !important;
  color: rgb(222, 5, 5) !important;
  padding: 0px !important;
  border-radius: 8px !important;
}

@media screen and (max-width: 768px) {
  .contact-btn {
    float: left !important;
  }
}

.contact-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  border: none;
  color: #ffffff;
  border-radius: 8px;
  width: 120px !important;
  background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important;
  float: right;
  height: 40px !important;
  margin-bottom: 20px;
}

.contact_label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #000000;
}

.textfield-div {
  width: 350px;
  height: 40px;
}
.textarea-div {
  width: 400px;
  height: 150px;
}

.message:focus,
input:focus {
  outline: none;
}

.input-field {
  border: none !important;
  width: 100%;
  border-radius: 12px;
  height: 100%;
  background: #ededed;
}

@media screen and (min-width: 1900px) and (max-width: 1960px) {
  .contactus-head {
    font-size: 26px !important;
  }
  .contactus-card span {
    font-size: 18px !important;
  }
  .contactus-card p {
    font-size: 15px !important;
  }
  .contactus-card .contactus-address {
    font-size: 18px !important;
  }
  .contact-formhead{
    font-size: 22px !important;
  }
  .contact_label{
    font-size: 15px !important;
  }
}

@media screen and (max-width: 900px) {
  .getin-touchdiv {
    margin: auto !important;
    float: none !important;
    padding: 20px !important;
    width: auto;
  }
  .contact-formhead {
    font-size: 18px !important;
  }
  .contact_label {
    font-size: 14px !important;
  }
  .contactus-head {
    font-size: 26px !important;
  }
  .contactus-subhead {
    font-size: 16px !important;
  }
  textarea,
  input {
    width: 280px !important;
  }
  .contactus-card span {
    font-size: 17px !important;
  }
  .contactus-card p {
    font-size: 15px !important;
  }
  .contactus-address {
    font-size: 15px !important;
  }
  .contactus-card {
    max-width: 90% !important;
    margin: auto !important;
  }
  .contactus-colone .contactus-card {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
