.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}


@media screen and (min-width: 1900px) and (max-width: 1960px) {
  .careercard-head {
    font-size: 26px !important;
  }
  .careercard-subhead {
    font-size: 16px !important;
  }
  .career-cardhead {
    font-size: 22px !important;
  }
  .careercard-left {
    font-size: 17px !important;
  }
  .careercard-right {
    font-size: 16px !important;
  }
  .applydiv span {
    font-size: 22px !important;
  }
  .career_label {
    font-size: 15px !important;
  }
}

.career {
  position: relative;
  overflow: hidden;
  background-image: url("../../../Assets/Career/Subtract\ .svg");
  background-repeat: no-repeat;
  background-position: right top;
  padding-top: 0px;
}

.career-header {
  height: 100px;
}

.careercontainer {
  position: relative;
  padding-top: 30px;
  z-index: 0;
  padding-bottom: 30px;
}

.career-coltwo {
  /* padding-right: 0; */
}

.applydiv {
  background: #ffffff;
  width: max-content !important;
  padding: 25px !important;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  text-align: left;
  float: right;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
}
.career_label {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #000000;
}

.job-btn button {
  min-width: 35px !important;
  color: #ffffff !important;
  height: 35px !important;
  background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important;
  border-radius: 8px;
  border: none;
  position: absolute;
  top: 65%;
}

/* .job-btn {
  display: flex;
  justify-content: end;
} */

.applydiv span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #313131;
}
.apply-formsection {
  padding-top: 20px;
  padding-bottom: 20px;
}
.applydiv .career-btn {
  margin-top: 20px;
}
.careerselect {
  width: 350px;
  height: 40px;
  background: #ededed !important;

  border-radius: 12px;
  border: none;
}
.uploadbtn-Career {
  width: 350px;
  height: 40px;
  text-transform: lowercase !important;
  color: rgb(140, 136, 136) !important;
  background: #ededed !important;
  box-shadow: none !important;
  border-radius: 12px !important;
  border: none;
}

.input-field {
  padding-top: 5px;
}

.apply-formsection .uploadbtn-Career {
  margin-bottom: 20px;
}
.upload-icon {
  display: flex;
  align-items: flex-end;
  float: right;
  justify-content: flex-end;
}
.career-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  border: none;
  color: #ffffff;
  border-radius: 8px !important;
  width: 120px !important;
  background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important;
  float: right !important;
  height: 40px !important;
}

.apply-formsection .career-btn {
  margin-top: 20px;
}

.careererror {
  min-height: 50px !important;
}

.careercard-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  text-align: left;
  color: #232323;
}
.careercard-subhead {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
  opacity: 0.5;
}
.career-card {
  position: relative;
  text-align: left;
  background: #ffffff;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 20px;
  width: 550px;
}
.career-cardhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #189547;
}
.careercard-left {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  color: #212121;
  opacity: 0.9;
}
.careercard-right {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
  opacity: 0.7;
}

.careercard-content {
  padding-top: 15px;
  padding-bottom: 0;
}
.careercard-btn {
  border: none;
  color: #ffffff;
  border-radius: 8px;
  width: 45px;
  height: 45px;
  background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important;
}
.careercardbtn-div {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
}

@media screen and (max-width: 900px) {

  .careererror {
    min-height: 100px !important;
  }

  .careercard-head {
    font-size: 26px !important;
  }

  .careercard-subhead {
    font-size: 16px !important;
  }
  .career-card {
    margin-top: 20px !important;
  }
  .careercontainer {
    margin: auto !important;
    padding-bottom: 40px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .career-cardhead {
    font-size: 20px !important;
  }
  .careercard-left {
    font-size: 18px !important;
  }
  .careercard-right {
    font-size: 18px !important ;
  }
  .applydiv span {
    font-size: 20px !important;
  }
  .career-btn {
    font-size: 12px !important;
  }
  .career_label {
    font-size: 14px !important;
  }
  .careerselect,
  .uploadbtn-Career {
    width: 280px !important;
  }
  .career-coltwo {
    padding-top: 20px !important;
    margin: auto !important;
  }
  .applydiv {
    padding: 10px !important;
    float: none !important;
    margin: auto !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .textfield-div {
    width: 260px !important;
  }
  .input-field {
    width: 260px !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  #career .errcon {
    font-size: 12px !important;
    margin: auto !important;
    text-align: center !important;
    padding-left: 60px !important;
    /* padding-right: 2px !important; */
  }
}

.textfield-div {
  width: 400px;
  height: 40px;
  background: #ededed !important;
  border-radius: 8px !important;
  /* padding-left: 8px; */
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textfield:focus,
input:focus {
  outline: none;
}

.careerselect:focus,
input:focus {
  outline: none;
}

.input-field {
  border: none !important;
  width: 100%;
  border-radius: 12px;
  padding-left: 10px;
  height: 100%;
  background: #ededed;
}
