.locationdiv {
  padding-top: 50px;
  /* padding-bottom: 50px; */
}

.locationhead {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  padding-bottom: 30px;
  color: black;
}
.locationimg img {
  object-fit: contain;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .locationhead {
    font-size: 28px !important;
    line-height: 38px !important;
  }
}
