.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}

.carousel-control-next-icon {
  background-image: url("../../../../Assets/Banner/Vector.svg") !important;
  size: 90px !important;
}

.carousel-control-prev-icon {
  background-image: url("../../../../Assets/Banner/Vector1.svg") !important;
  size: 90px !important;
}
.carousel-indicators {
  display: flex;
  align-items: center;
  text-shadow: 2px 2px 4px black;
}
.carousel-indicators button {
  width: 30px !important;
  height: 10px !important;
  border-radius: 5px !important;
  border: 0 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.carousel-indicators [data-bs-target].active {
  width: 60px !important;
  height: 15px !important;
  border-radius: 10px !important;
}
@media (max-width: 992px) {
  .carousel-indicators button {
    width: 16px !important;
    height: 5px !important;
  }

  .carousel-indicators [data-bs-target].active {
    width: 30px !important;
    height: 10px !important;
  }
}

