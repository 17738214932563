.item-main-head {
  background: linear-gradient(270.1deg, #54a13f -1.37%, #59ab43 98.99%);
}

.item-head {
  color: #1e1e1e;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 42px;
  /* 131.25% */
  text-transform: capitalize;
  text-align: left;
}

.item-design {
  background-image: url("../../../Assets/Item/doodleart.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.item-category {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: left;
  color: #0e0e0e;
  padding-top: 60px;
}

.ob-item-title {
  font-family: "Poppins";
  font-size: 22px !important;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 60px;
  color: white;
}

@media (min-width: 992px) {

  .item-head {
    font-size: 32px;
    line-height: 42px;
  }
  
  .ob-item-title {
    font-size: 40px;
    line-height: 48px;
  }
}

