/* import SuperMarketBG from "../../../../Assets/Lulu/super_bg.jpg" */


.lulu-container {
    width: 100%;
    height: auto;
    background: url("../../../../Assets/Lulu/super_bg_mob.jpg") no-repeat;
    background-size: cover;
    background-position: center;
}



.lulu-content {
    padding-top: 47px;
    padding-bottom: 47px;
}

.lulu-img{
    padding-top: 20px;
    padding-bottom: 20px;
}

.available-at {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.hyper-market {
    color: #E31D1A;
    text-align: center;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.middle-east {
    color: #181B18;
    text-align: center;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .lulu-container {
        background: url("../../../../Assets/Lulu/super_bg_mob.jpg") no-repeat !important;
        background-size: cover !important;
        background-position: center !important; 
    }
   }


@media screen and (min-width: 1024px) {
    .lulu-container {
        background: url("../../../../Assets/Lulu/super_bg.jpg") no-repeat !important;
        background-size: 100% 100% !important;
    }

    .available-at {
        font-size: 21px;
    }
    
    
    .hyper-market {
        font-size: 23px;
    }
    
    .middle-east {
        font-size: 22px;
    }
}