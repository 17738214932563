@media screen and (min-width: 1900px) and (max-width: 1960px) {
  .item-subhead {
    font-size: 22px !important;
  }
  .item-coltwo p {
    font-size: 18px !important;
    line-height: 27px !important;
  }
  .item-lastrowcontainer .p1{
    font-size: 24px !important;
  }
  .item-title{
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}
.item-row {
  padding: 5px;
  margin-top: 50px;
}
.item-colone img {
  width: 60%;
  padding-top: 50px;
}
.item-coltwo {
  text-align: left;
  padding-left: 30px;
}
.item-colone {
  background-image: url("../../../Assets//Item/Ellipse.png");
  background-repeat: no-repeat;
  background-position: center 85%;
}

.box-btn {
  width: 52px;
  height: 52px;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
  background: #d9d9d9;
  border-radius: 8px;
}
.boxbtn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: auto;
}
.item-quantity {
  display: flex;
  gap: 75px;
  position: relative;
  padding-top: 32px;
}
.itemhead {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0e0e0e;
  opacity: 0.3;
}
.item-mainhead {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  color: #0e0e0e;
}
.item-subhead {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 24px !important;
  text-transform: capitalize !important;
  padding-top: 32px !important;
  color: #0e0e0e !important;
}
.item-coltwo p {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
  word-spacing: 1px;
  /* opacity: 0.8; */
}
@media (max-width: 992px) {
  .item-coltwo {
    width: auto !important;
  }
  .item-coltwo p {
    font-size: 16px !important;
  }
  .item-subhead {
    font-size: 18px !important;
  }
  .item-mainhead {
    font-size: 20px !important;
  }
  .box-btn {
    width: 32px;
    height: 32px;
  }
  .item-colone img {
    width: 50% !important;
  }

  .item-container .item-card {
    margin: auto !important;
  }
  .item-image {
    width: 100% !important;
    min-height: 100px !important;
    object-fit: contain !important;
  }
  .item-card {
    min-width: 100px;
    min-height: 100px !important;
  }
  .item-title {
    font-size: 16px !important;
  }
  .item-card {
    max-width: 120px !important;
    width: 130px !important;
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
  .item-subhead {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    text-transform: capitalize !important;
    padding-top: 32px !important;
    color: #0e0e0e !important;
  }

  .item-coltwo p {
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 26px;
    color: #212121;
    opacity: 0.8;
  }
  .item-title {
    min-height: 60px !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    text-transform: capitalize;
    color: #000000;
  }
}

.item-lastrowcontainer {
  width: 100%;
}
.item-lastrowcontainer p {
  font-family: "Poppins" !important;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
  margin-left: 10px;
  padding-top: 15px;
  transform: translate(-27px, 23px);
}

.p1 {
  font-family: "Poppins" !important;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
  margin-left: 10px;
  padding-top: 15px;
  /* transform: translate(-20px, 23px); */
  padding-left: 20px !important;
}

.item-coltwo span {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
  /* opacity: 0.4; */
}

.item-rowlast {
  padding-top: 30px;
  /* overflow-x: scroll !important; */
  width: 100%;
}

.itemsection {
  padding-bottom: 50px;
  background-color: #e0eee5;
}

.item-rowlast img {
  object-fit: contain !important;
}

.item-scroll img {
  object-fit: contain !important;
  max-height: 200px;
}

.css-16cvb1l-MuiCardMedia-root {
  background-size: contain !important;
}

.item-scroll {
  /* display: flex; */
  gap: 35px !important;
  margin-bottom: 25px;
}

.item-scroll .MuiPaper-root {
  width: 197px;
  box-shadow: 0px 10px #499e4c;
  max-height: 530px !important;
  margin-top: 25px;
}

.item-carddiv {
  padding-top: 40px;
}
.item-card {
  position: relative;
  min-height: 300px;
  padding: 20px;
  margin-right: 15px !important;
  margin-bottom: 50px !important;
  min-height: 300px;
  min-width: 197px;
  /* background: linear-gradient(
    360deg,
    #ffffff 9.05%,
    rgba(255, 255, 255, 0) 137.86%
  ) !important; */
  /* filter: drop-shadow(3px 3px 12px rgba(0, 0, 0, 0.12)) !important; */
  border-radius: 16px !important;
}
.item-image {
  width: 100%;
  min-height: 120px;
}

#item .css-46bh2p-MuiCardContent-root {
  padding: 0px !important;
  margin-top: 15px;
  /* margin-left: 15px; */
}

@media screen and (min-width:993px) {
  .item-title {
    min-height: 60px !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    text-transform: capitalize;
    color: #000000;
  } 
}

.no-image{
  display: flex;
  justify-content: center;
  align-items: center;
}



.item-gram {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 18px;

  text-transform: lowercase;

  color: #000000;

  /* opacity: 0.6; */
}
.item-cardaction .item-btn {
  float: right !important;
  display: flex !important;
  justify-content: flex-end !important;
}
.item-btn {
  min-width: 35px !important;
  color: #ffffff !important;
  height: 35px !important;
  /* background: linear-gradient(281.3deg, #118933 6.01%, #74b858 119.99%),
    rgba(116, 185, 69, 1) !important; */
  background-color: #499e4c !important;
}
.item-card .item-btn {
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-radius: 10px 0px;
}
.item-arrow {
  text-align: center;
}

::-webkit-scrollbar {
  max-width: 50% !important;

  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #ffffff;
  width: 50% !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #d4d4d4;
}

::-webkit-scrollbar:vertical {
  display: none;
}

@media screen and(max-width: 780px) {
  .item-scroll {
    scroll-snap-align: start !important;
    scroll-snap-type: x mandatory !important;
    width: 100vw !important;
  }
}

@media screen and(max-width: 1224px) {
  .item-scroll {
    gap: 80px !important;
  }
}

.incredients-container {
  margin-top: 50px;
}

@media screen and (max-width: 414px) {
  .top {
    background-size: contain;
    background-position: center 70% !important;
  }

  #mainImage {
    /* margin-bottom: 80px !important; */
  }
}

.top {
  background-color: white;
  /* width: 400px; */
  border-radius: 25px;
  box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.15);
}

#mainImage {
  width: 444px !important;
  object-fit: none;
  margin-bottom: 40px;
  min-height: 340px;
}

.sub-item {
  margin-top: 38px;
  margin-bottom: 50px;
}

.incredients-btn {
  border: none;
  background: none;
  object-fit: contain;
}

.incredients-btn img {
  object-fit: contain;
  width: 52px;
  padding: 7px;
}

.incredients-btn {
  margin-right: 10px;
  border: none;
  background: #d9d9d9;
  border-radius: 8px;
  transition: 0.4s ease-in !important;
}

.incredients-btn :focus {
  transition: 0.8s ease-out !important;
  width: 60px;
}

.imageCarousel {
  border-radius: 10px;
  cursor: pointer;
}

.css-pqdqbj img {
  display: block;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
}

.item-category {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: left;
  color: #0e0e0e;
  padding-top: 60px;
}

.item-name {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 60px;
  color: white;
}

.item-main-head {
  background: linear-gradient(270.1deg, #54a13f -1.37%, #59ab43 98.99%);
}

.item-design {
  background-image: url("../../../Assets/Item/doodleart.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.gmview {
  display: flex;
  transform: translate(-10px, 20px);
  align-items: center;
}

.gmview span {
  transform: translate(80px, 0px);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: auto;
}

.elipse img {
  position: absolute;
  right: 0;
  top: 25px;
}

.spitem {
  opacity: 0.4;
  font-weight: 500;
  font-size: 18px;
}


@media screen and (min-width:760px) and (max-width:860px) {
  .elipse img {
    position: absolute;
    right: 0;
    top: -25px !important;
}
}


@media screen and (min-width:320px) and (max-width:390px) {
  .elipse img {
    position: relative !important;
    top: 25px;
    transform: translate(-61%, 0px) !important;
    /* width: 100%; */
  }
}

@media screen and (min-width:390px) and (max-width:450px) {
  .elipse img {
    position: relative !important;
    top: 25px;
    transform: translate(-50%, 0px) !important;
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 450px) {
  .top {
    width: 100% !important;
  }
  .item-quantity {
    position: initial !important;
  }
 
  .item-lastrowcontainer {
    margin-top: 50px !important;
  }
  .item-name {
    padding-bottom: 25px !important;
    font-size: 35px !important;
  }
  .item-category {
    padding-top: 25px !important;
  }
  #mainImage {
    max-height: 200px !important;
    width: 250px !important;
    margin-top: 25px !important;
    object-fit: cover !important;
  }
}

.item-lastrowcontainer .carousel-control-prev {
  left: -120px;
}

.item-lastrowcontainer .carousel-control-next {
  right: -120px;
}

.item-lastrowcontainer .carousel-control-prev-icon {
  background-image: url("../../../Assets/products/prev.svg") !important;
  background-repeat: no-repeat;
  width: 15px;
}

.item-lastrowcontainer .carousel-control-next-icon {
  background-image: url("../../../Assets/products/next.svg") !important;
  background-repeat: no-repeat;
  width: 15px;
}

.item-lastrowcontainer .carousel-indicators {
  display: none;
}

.g-view {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  margin-left: auto;
  text-align: left;
  transform: translate(-14px, 30px);
}
