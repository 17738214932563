.errok {
  width: 25%;
  font-size: 14px !important;
  margin: auto !important;
}
.dialog {
  padding: 30px !important;
}
.errcon {
  font-size: 12px !important;
  margin: auto !important;
  text-align: center !important;
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.errheadstl {
  font-size: 12px !important;
  text-align: center;
  text-transform: capitalize !important;
  color: #3ba24e !important;
  padding-top: 20px !important;
  padding-bottom: 30px !important;
}
.errdelete {
  background-color: #3ba24e !important;
  color: white !important;
  justify-content: center !important;
}
.actoionbtn {
  justify-content: center !important;
  padding-bottom: 50px !important;
}

@media screen and (min-width:390px) and (max-width:450px) {
  .errcon {
    font-size: 12px !important;
    margin: auto !important;
    text-align: center !important;
    padding-left: 60px !important;
    padding-right: 2px !important;
}
}