@media screen and (min-width: 320px) and (max-width: 480px) {
  .homecategory-heading {
    padding-top: 90px !important;
    padding-bottom: 70px !important;
    font-size: 28px !important;
  }
  .product-name {
    text-align: center !important;
    max-width: none !important;
    font-size: 27px !important;
  }
  .product-desc {
    text-align: center !important;
    max-width: none !important;
  }
  .view-details {
    text-align: center !important;
  }
}

.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}

@media screen and (min-width: 1900px) and (max-width: 1930px) {
  .sub-heading span {
    font-size: 26px !important;
  }
  .homecategory-heading {
    font-size: 40px !important;
  }
  .product-desc {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 3500px) and (max-width: 4000px) {
  .homecategory-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 60px !important;
    line-height: 46px;
    color: black;
    padding-top: 120px;
    padding-bottom: 50px;
  }
  .product-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 25px;
    color: #605d5d;
    text-align: center;
    max-width: 285px;
  }
}

.category-bg2 {
  background-image: url("../../../../Assets/Doodle\ pattern\ \(1\).svg");
  /* background-repeat:no-repeat; */
  background-size: contain;
  background-position-x: center;
}

.homecategory-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  line-height: 46px;
  color: black;
  padding-top: 120px;
  padding-bottom: 50px;
}

.product-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #212121;
  text-align: center;
  max-width: 285px;
}

.product-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #605d5d;
  text-align: center;
  max-width: 285px;
}

.view-details {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #299b4a;
  text-align: center;
  text-transform: lowercase;
}

.more-btn {
  text-transform: capitalize !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 28px !important;
  color: #299b4a !important;
  text-align: left;
  padding-left: inherit !important;
}

.home-category-col img {
  max-width: 260px;
  object-fit: contain;
  margin: auto;
}

.home-category-col {
  margin-bottom: 50px;
  display: grid;
  justify-content: center;
}

.view-more-ctpage {
  background: #d3e338;
  width: 135px;
  height: 35px;
  border-radius: 30px;
  margin: auto;
  margin-top: 80px;
  display: none;
}

.all-btn {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  color: black !important ;
  text-transform: none !important;
}

.HCP {
  padding-bottom: 70px;
}

.homecategory-row {
  display: flex;
  align-items: center;
}
