@media screen and (min-width: 320px) and (max-width: 480px) {
  .homecategory-heading {
    padding-top: 90px !important;
    padding-bottom: 70px !important;
  }
  .product-name {
    text-align: center !important;
    max-width: none !important;
  }
  .product-desc {
    text-align: center !important;
    max-width: none !important;
  }
  .view-details {
    text-align: center !important;
  }
  .category-design {
    background-image: url("../../../../../Assets/Category/Doodlepattern.svg") !important;
    background-repeat: repeat !important;
    background-size: contain !important;
    background-position-x: center;
  }
  .home-category-col{
    transition: none !important ;
  }
  .home-category-col:hover {
    transition: none !important ;
    transform: none !important ;
  }
  
}

.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}


.category-bg {
  background: linear-gradient(180deg, #eff9f2 0%, #f4f4f4 100%);
}

.category-design {
  background-image: url("../../../../../Assets/Category/Doodlepattern.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}

.homecategory-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  line-height: 46px;
  color: black;
  padding-top: 120px;
  padding-bottom: 50px;
}

.product-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #212121;
  text-align: center;
  max-width: 285px;
}

.product-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #605d5d;
  text-align: center;
  max-width: 285px;
}

.view-details {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #299b4a;
  text-align: center;
  text-transform: lowercase;
}

.more-btn {
  text-transform: capitalize !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 28px !important;
  color: #299b4a !important;
  text-align: left;
  padding-left: inherit !important;
}

.home-category-col img {
  max-width: 260px;
  object-fit: contain;
  margin: auto;
}

.home-category-col {
  margin-bottom: 50px;
  display: grid;
  justify-content: center;
}

.home-category-col{
  transition: 0.4s ease-in ;
}
.home-category-col:hover {
  transition: 0.8s ease-out ;
  transform: scale(1.1) ;
}

/* .view-more {
  background: #d3e338;
  width: 135px;
  height: 35px;
  border-radius: 30px;
  margin: auto;
  margin-top: 80px;
} */

.all-btn {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  color: black !important ;
  text-transform: none !important;
  background: #d3e338 !important;
  width: 135px !important;
  height: 35px !important;
  border-radius: 30px !important;
  margin: auto !important;
  margin-top: 80px !important;
}

.HCP {
  padding-bottom: 70px;
}

.homecategory-row {
  display: flex;
  align-items: center;
}
