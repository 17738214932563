.navbar {
  width: 100%;
  top: 0;
  font-family: "Poppins" !important;
  z-index: 999;
  margin: 0;
  font-size: 16px;
  padding: 0px !important;
  font-weight: bold !important;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 3500px) and (max-width: 4000px) {
  .navbar-nav .nav-link {
    color: black;
    font-weight: 400;
    margin-right: 0px;
    font-family: inherit !important;
    font-size: 24px !important;
  }
}

@media screen and (max-width: 900px) {
  .navbar {
    z-index: 0;
  }
  .nav-logo img {
    z-index: 100000 !important;
  }
  .off-logo {
    display: flex !important;
    margin: auto !important;
    width: 50% !important;
  }
}

.navbar-nav .nav-link {
  color: black;
  font-weight: 400;
  margin-right: 0px;
  font-family: inherit !important;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: rgba(45, 157, 75, 1) !important;
  font-weight: bold !important;
  font-family: inherit !important;
}

.navbar-nav .nav-link:hover {
  color: rgb(rgba(45, 157, 75, 1)) !important;
  box-shadow: none;
}

.navBtn {
  height: 45px;
  background: rgba(45, 157, 75, 1);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  color: white;
  border: none;
  padding: 0px 24px;
  display: block;
}

.navBtn:hover {
  background-color: rgba(45, 157, 75, 1) !important;
  border-color: rgba(45, 157, 75, 1) !important;
  color: white !important;
}

@media (max-width: 992px) {
  .navbar-brand img {
    width: 100% !important;
  }
  .navbar-brand {
    width: 18% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 370px) {
  .offcanvas-body {
    display: grid !important;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    /* margin: auto !important; */
    transform: translate(5%, -10%) !important;
  }
  .navbar {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .off-logo {
    margin: auto !important;
    width: 50% !important;
    transform: translate(17px, 10px) !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 450px) {
  .offcanvas-body {
    display: grid !important;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    /* margin: auto !important; */
    transform: translate(0%, -15%) !important;
  }
  .navbar {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .off-logo {
    margin: auto !important;
    width: 50% !important;
  }
}

@media screen and (min-width: 422px) and (max-width: 450px) {
  .offcanvas {
    width: 100% !important;
  }
  .off-logo {
    margin: auto !important;
    width: 50% !important;
  }
}

.navbar.nav-sticky .nav-link {
  color: black;
  text-shadow: none;
  transition: 0.6s;
  font-size: 16px !important;
  font-family: inherit !important;
}
.offcanvas {
  background-color: white !important;
  max-width: inherit;
}

@media (min-width: 992px) {
  .navbar.nav-sticky {
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    position: fixed;
    width: 100%;
    margin: 0;
  }

  .offcanvas {
    background-color: transparent !important;
  }

  .navbar.nav-sticky .navbar-nav {
    transition: 0.6s;
  }

  .navbar.nav-sticky .brand-name {
    display: block;
    transition: 0.6s;
  }

  .navbar.nav-sticky .navBtn {
    background: rgba(45, 157, 75, 1);
    color: white !important;
  }

  .navbar.nav-sticky .navBtn:hover {
    background: rgba(45, 157, 75, 1);
    border-color: rgba(45, 157, 75, 1) !important;

    color: white !important;
  }
}

/* ===============================
=            General            =
=============================== */
.navbar-brand {
  width: 10%;
}
.navLink.black {
  color: black;
  text-shadow: none;
  z-index: 99999;
  font-weight: 400;
}

#navbarhome-id .navbar-nav > .nav-link > .active > a::after {
  font-size: 50px !important;
  color: rgb(rgba(45, 157, 75, 1)) !important;
}

.navBtn span {
  color: white !important;
}

.navBtn {
  color: white !important;
}

.offcanvas-header img {
  position: absolute;
  /* right: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  transform: translate(91%, 100%);
}

@media screen and (min-width:900px) {
  .off-logo {
    display: none;
  }
}


