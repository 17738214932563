@media screen and (min-width: 760px) and (max-width: 900px) {
  .stick-col img {
    display: none !important;
  }
  .wheat1 img {
    position: absolute;
    left: 0;
    top: 4.2% !important;
  }
  .wheat2 img {
    position: absolute;
    right: 0;
    top: 4% !important;
}
}

.css-18lrjg1-MuiCircularProgress-root {
  color: rgba(45, 157, 75, 1) !important;
}

@media screen and (min-width: 3500px) and (max-width: 4000px) {
  .three-image img {
    width: 100%;
    height: 845px !important;
    object-fit: cover !important;
  }
  .mission-head {
    color: rgba(45, 157, 75, 1);
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 27px !important;
    padding-bottom: 20px;
  }
  .vision-head {
    color: rgba(45, 157, 75, 1);
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 27px !important;
    padding-bottom: 20px;
    margin-top: 24px;
  }
  .svg-backgroundone {
    background-image: url("../../../../Assets/About/vision-bg.svg");
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }

  .coltwo p {
    font-size: 25px !important;
    font-weight: 500px !important;
    line-height: 35px !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    text-align: justify;
  }
  .aboutcontainertwo .founder {
    font-family: "Poppins" !important;
    font-size: 50px !important;
    color: black;
    padding-top: 10px;
    font-weight: 600px !important;
    font-style: normal !important;
  }
  .founderwords {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400;
    text-align: justify;
    color: rgba(33, 33, 33, 1) !important;
    font-size: 16px !important;
    line-height: 26px !important;
    padding-top: 14px;
    padding-bottom: 18px;
    letter-spacing: 0em !important;
  }

  .wheat1 img {
    position: absolute;
    left: 0;
    top: 23.2% !important;
  }

  .wheat2 img {
    position: absolute;
    right: 0;
    top: 22% !important;
  }
  .cardfounder {
    border-radius: 20px !important;
    margin: auto;
    max-width: 338px;
    min-height: 510px !important;
    padding: 20px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16) !important;
  }
  .three-contents p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 30px !important;
    line-height: 40px !important;
    text-align: justify;
    color: #ffffff;
    padding: 0px 25px;
  }
  .sp1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 60px !important;
    line-height: 55px !important;
    color: #ffffff;
  }

  .sp2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 60px !important;
    line-height: 55px !important;
    color: #ffffff;
  }
}

.containerone {
  padding-top: 100px;
  padding-bottom: 60px;
}
.logoimg img {
  width: 55%;
  margin: auto;
  margin-top: 24px;
}

@media screen and (max-width: 450px) {
  .three-contents p {
    padding: 0px 7px !important;
  }
  .aboutcontainertwo .founder{
    font-size: 28px !important;
    font-weight: 700 !important;
  }
  .sp1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 28px !important;
    line-height: 48px;
    color: #ffffff;
    display: flex !important;
  }

  .sp2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 28px !important;
    line-height: 20px !important;
    color: #ffffff;
  }
}


@media screen and (min-width: 1900px) and (max-width: 1935px) {
  .coltwo p{
    font-size: 20px !important;
  }
  .aboutcontainerone {
    background-size: cover !important;
  }
  .svg-backgroundone {
    background-size: cover !important;
  }
  .wheat2 img {
    top: 13% !important;
  }
  .wheat1 img {
    top: 14% !important;
  }
  .coltwo {
    text-align: left;
    padding-right: 5px !important;
  }
}

@media screen and (min-width:320px) and (max-width: 450px) {
  .foundercoltwo{
    padding-bottom: 80px !important;
  }
  .containertwo {
    padding: 0px !important;
    padding-top:80px !important;
  }
  .cardfounder {
    /* max-width: 250px !important; */
    padding: 0px !important;
  }
  .founderwords {
    font-size: 15px !important;
    line-height: 22px !important;
    letter-spacing: 0em !important;
    word-spacing: normal !important;
  }
  .aboutcontainerone {
    background-size: cover !important;
  }
  .aboutcontainerthree p {
    font-size: 16px !important;
    line-height: 27px !important;
    text-align: justify !important;
  }
  .stick-col {
    display: none !important;
  }
  .ellipsegroup img {
    width: 60% !important;
  }

  .containerone {
    padding-bottom: 10px !important;
    padding-top: 20px !important;
  }
  .logoimg img {
    width: 40% !important;
  }
  .ellipseimg {
    padding-top: 15px !important;
  }
  .coltwo-firstrow {
    padding-bottom: 25px !important;
  }

  .coltwo p {
    font-size: 16px !important;
    width: 100%;
    line-height: 25px !important;
  }
  .colone {
    padding-top: 0px;
  }
  .coltwo {
    padding-top: 50px;
  }
  .coltwo {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .tagline {
    font-size: 18px !important;
  }
  .vision-head,
  .mission-head {
    font-size: 20px !important;
  }

  .three-image img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }
}

@media screen and (min-width: 540px) and (max-width: 900px) {
  .stick-col img {
    display: none !important;
  }
  .containerone {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }
  .colone img {
    width: 40% !important;
  }
  .ellipseimg {
    padding-top: 15px !important;
  }

  .coltwo p {
    font-size: 16px !important;
    width: 100%;
    line-height: 20px !important;
  }
  .colone {
    padding-top: 0px;
  }
  .coltwo {
    padding-top: 50px;
  }
  .coltwo {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .tagline {
    font-size: 16px !important;
  }
  .vision-head,
  .mission-head {
    font-size: 20px !important;
  }
}

.aboutcontainerone {
  background-image: url("../../../../Assets/About/pinkbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.svg-backgroundone {
  background-image: url("../../../../Assets/About/vision-bg.svg");
  width: 100%;
  height: 100%;
}

.wheat1 img {
  position: absolute;
  left: 0;
  top: 12.7%;
}

.wheat2 img {
  position: absolute;
  right: 0;
  top: 10%;
}

.ellipseimg {
  margin: auto;
  object-fit: contain;
  padding-top: 35px;
}

.tagline {
  font-family: "Poppins" !important;
  font-size: 20px;
  color: black;
  font-style: normal !important;
  display: flex;
  justify-content: center;
  font-weight: 400px;
}
.coltwo {
  text-align: left;
  padding-right: 150px;
}

.coltwo p {
  font-size: 18px;
  font-weight: 500px !important;
  line-height: 25px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  text-align: justify;
}
.vision-head {
  color: rgba(45, 157, 75, 1);
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 27px !important;
  padding-bottom: 20px;
  margin-top: 24px;
}
.coltwo-firstrow {
  padding-bottom: 48px;
}
.mission-head {
  color: rgba(45, 157, 75, 1);
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 27px !important;
  padding-bottom: 20px;
}

.aboutcontainertwo {
  background-image: url("../../../../Assets/About/bg-art.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutcontainertwo .founder {
  font-family: "Poppins" !important;
  font-size: 32px;
  color: black;
  padding-top: 10px;
  font-weight: 600px !important;
  font-style: normal !important;
}
.avatar {
  text-align: center;
  margin: auto;
}
.containertwo {
  padding: 80px;
}
.cardfounder {
  border-radius: 20px !important;
  margin: auto;
  max-width: 300px;
  min-height: 400px;
  /* padding: 5px; */
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16) !important;
}
@media screen and (min-width: 1900px) and (max-width: 2000px) {
  .three-contents p {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 455px) and (max-width: 900px) {
  .founderwords {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .foundersunhead {
    font-size: 10px !important;
  }
  .foundername {
    font-size: 16px !important;
  }
  .cardfounder {
    max-width: 300px !important;
  }
  .aboutcontainertwo .founder {
    font-size: 24px !important;
  }
  .containertwo {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
.foundercolone,
.foundercoltwo {
  padding-top: 20px;
}
.founderwords {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
  text-align: justify;
  color: rgba(33, 33, 33, 1) !important;
  font-size: 16px;
  line-height: 26px;
  padding-top: 14px;
  padding-bottom: 18px;
  letter-spacing: 0em !important;
}

.foundername {
  font-family: "Poppins" !important ;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27px;
  color: #4a4a4a;
  padding-top: 24px;
}
.foundersunhead {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 27px;
  opacity: 0.6 !important;
  color: #4a4a4a;
}

.section-three {
  background: linear-gradient(115.59deg, #eb4d3a 28.74%, #cc4333 100.01%);
}

.three-image img {
  width: 100%;
  height: 705px;
  object-fit: cover;
}

.three-content {
  background-image: url("../../../../Assets/About/art-red.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
}

.heading-line {
  margin-top: 105px;
  text-align: left;
  background-image: url("../../../../Assets/About/Line2.svg");
  background-repeat: no-repeat;
}

.content-heading {
  padding-top: 20px;
  padding-bottom: 25px;
}

.undeline {
  float: right;
  margin-bottom: 90px;
}

.sp1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #ffffff;
}

.sp2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  color: #ffffff;
}

.three-contents p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  color: #ffffff;
  padding: 0px 25px;
}

.content-bottom {
  padding-bottom: 25px;
}

.leftblackimg-div {
  position: absolute;
}

.leftblackimg {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.cookingcol {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: left;
  padding-left: 80px;
}
.leafimg img {
  position: absolute;
  right: 0%;
  top: 0;
  transform: translate(0%, 0%);
}

@media screen and (max-width: 900px) {
  .leftblackimg {
    width: 80%;
  }
  .whatscooking {
    font-size: 24px !important;
  }
  .iswhoweare {
    font-size: 22px !important;
  }
  .leafimg img {
    width: 30%;
  }
  .cookingcol p {
    margin: auto !important;
  }
  .cookingcol {
    padding-left: 10px !important;
  }
  .cookingcol .cookinghead {
    text-align: center;
  }
  /* .aboutcontainerthree p {
    font-size: 12px !important;
    line-height: 20px !important;
  } */
}

.whatscooking {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px;
  line-height: 48px !important;
  color: #2d9d4b;
}
.iswhoweare {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 28px;
  line-height: 48px !important;
  text-transform: capitalize;
  color: #ffffff;
}

.aboutcontainerfour {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}
.greenhead {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px;
  line-height: 27px;
  color: #189547;
}
.blackhead {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 28px;
  color: rgba(49, 49, 49, 1);
  line-height: 27px;
}

.aboutcontainerfour p {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 32px;
  padding-top: 40px;
  text-align: center;
  color: #212121;
  opacity: 0.9;
}
.indianideadiv {
  margin: auto !important;
  width: 60%;
}

.powderdiv img {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0%, 0);
}
.chickenimgdiv img {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0%, 0);
}

@media screen and (max-width: 900px) {
  .aboutcontainerfour p {
    font-size: 12px !important;
    line-height: 20px !important;
    text-align: center !important;
  }
  .indianideadiv {
    width: 80% !important;
  }
  .chickenimgdiv img {
    width: 30% !important;
  }
  .powderdiv img {
    width: 20% !important;
  }
  .blackhead {
    font-size: 22px !important;
  }
  .greenhead {
    font-size: 24px !important;
  }
}

.aboutcontainerfive {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px;
}

.fulfillcontent {
  background-color: #ffffff;
  box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.2) !important;
  width: 80%;
  border-radius: 24px;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width: 900px) {
  .aboutcontainerfive {
    padding: 20px !important;
  }
  .fulfillcontent {
    width: 100% !important;
  }
  .fulfillcontent p {
    font-size: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 20px !important;
  }
  .fulfillhead {
    font-size: 24px !important;
  }
  .bottleimg img {
    width: 30% !important;
    margin-bottom: 15px;
  }
}

.fulfillhead {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 32px;
  line-height: 46px;
  color: #189547;
}

.fulfillcontent p {
  padding-top: 20px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px;
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 32px;
  color: #212121;
}

.containerfive {
  position: relative;
}

.bottleimg img {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(5%, 30%);
  width: 20%;
  padding-top: 40px;
}
.bulbimg img {
  position: absolute;
  top: 50;
  left: 0;
  transform: translate(25%, -20%);
  width: 150px;
}

@media screen and (max-width: 900px) {
  .containerfive {
    margin-top: 10px;
  }
  .bulbimg {
    width: 120px !important;
    height: 120px !important;
    top: 50;
    left: 0;
    transform: translate(-35%, -20%);
    position: absolute;
    display: none;
  }
}

@media screen and (min-width: 760px) and (max-width: 1280px) {
  .bottleimg img {
    transform: translate(1%, 20%) !important;
  }
}

@media screen and (min-width: 912px) and (max-width: 1280px) {
  .bulbimg img {
    transform: translate(10%, -25%) !important;
    width: 150px;
  }
}

@media screen and (max-width: 425px) {
  .three-content {
    background-image: url("../../../../Assets/About/art-red.svg") !important;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover !important ;
  }
}
.three-content {
  background-image: url("../../../../Assets/About/art-red.svg") !important;
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
}
